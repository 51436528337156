import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { notification } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import moment from 'moment'
import FrontPage from "./FrontPage";
const PaymentComponent = () => {
  const navigate = useNavigate()
  const [data, setData] = useState(null)
  const [showPaymentDone, setShowPaymentDone] = useState(false)
  // let currentLocation = useLocation().pathname;
  // console.log(barcode, currentLocation.split('/'))
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  // Get the value of the 'q' parameter
  const barcode = params.get('q');
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/visitor-detail-by-barcode/${barcode}`)
      .then((res) => {
        if (res?.data?.data?.payment_status) {

          setShowPaymentDone(res?.data?.data?.payment_status)
        } else if (res?.data?.data?.approved_status === "pre_approved" || res?.data?.data?.approved_status === "pre_approved_agent") {

          setData(res.data.data)

        } else {

          notification.error({ message: "You are not authorized to make this payment.", description: '' });
        }

      })
      .catch((err) => {
        // LoginRedirection("/superadmin/login");
        if (err.response && err.response.status === 401) {
          navigate("/superadmin/login");
          localStorage.removeItem("jff_token");
          localStorage.removeItem("category");
          localStorage.removeItem("permission");
          localStorage.removeItem("user_id");
          localStorage.removeItem("type_of_user");
        }
      });
  }, [])
  useEffect(() => {
    if (data) {
      console.log("hello ", data)
      createRazorypayOrder(
        process.env.REACT_APP_RAZORPAY_AMOUNT,
        data.barcode,
        moment(data.createdAt).format("MMM Do YY")
      );
    }
  }, [data])

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };


  const createRazorypayOrder = (
    amount,
    barcode,
    time,
    visitorPhoneNumber,
    visitorEmailId
  ) => {
    let data = JSON.stringify({
      amount: amount,
      currency: "INR",
      barcode,
    });
    axios
      .post(`${process.env.REACT_APP_API_END_POINT}/payment-request`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((resData) => {
        console.log("hello i am testd here", resData.data);
        handleRazorpayScreen(
          resData.data.amount,
          resData.data.order_id,
          barcode,
          time,
          visitorPhoneNumber,
          visitorEmailId
        );
      })
      .catch((error) => {
        console.log("error in payment:=>", error.message);
      });
  };

  const handleRazorpayScreen = async (
    amount,
    order_id,
    barcode,
    time,
    visitorPhoneNumber,
    visitorEmailId
  ) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      notification.error({ message: "some thing is not good", description: '' });
      return;
    }
    const options = {
      // key: "rzp_live_vGtDUlX79LRuv8"
      key: process.env.REACT_APP_RAZORPAY_API_KEY,
      amount: amount,
      currency: "INR",
      name: process.env.REACT_APP_ORGANIZER_NAME,
      order_id,
      description: `Pay To ${process.env.REACT_APP_ORGANIZER_NAME}`,
      image: process.env.REACT_APP_RAZORPAY_LOGO,
      notes: { barcode },
      handler: function (response) {
        setData(null)

        console.log("responserazorpay_payment_id", response);
        axios
          .get(
            `${process.env.REACT_APP_API_END_POINT}/payment/${response.razorpay_payment_id}/${barcode}/success`
          )
          .then((payment_Data) => {
            console.log("tested", payment_Data.data);
            navigate("/payment-successful", {
              state: {
                visitorListData: barcode,
                visitorName: data.visitor_name,
                registrationDate: time,
              },

            });
            // Set a timeout to reload the page 500 milliseconds after the navigation
            setTimeout(() => {
              window.location.reload();
            }, 50);
          });
        // setResponseId(response.razorpay_payment_id);


      },
      modal: {
        ondismiss: function (response) {
          setData(null)

          console.log("responserazorpay_payment_id", response);
          axios
            .get(
              `${process.env.REACT_APP_API_END_POINT}/payment/demo/${barcode}/failed`
            )
            .then((payment_Data) => {
              console.log("tested", payment_Data.data);
              navigate("/payment-failed", {
                state: {
                  visitorListData: barcode,
                  visitorName: data.visitor_name,
                  registrationDate: time,
                },
              });
              // Set a timeout to reload the page 500 milliseconds after the navigation
              setTimeout(() => {
                window.location.reload();
              }, 50);
            });
          // handle failed or canceled payment

        },
      },
      prefill: {
        name: data.visitor_name,
        email: visitorEmailId,
        contact: visitorPhoneNumber,
      },
      theme: {
        color: "#F4C430",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  return (
    <div>
      {showPaymentDone
        ? <>
          <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div className="card p-4 shadow-lg" style={{ maxWidth: '500px', width: '100%' }}>
              <div className="text-center">
                <h3 className="mb-3 text-success">Payment Allready Done!</h3>
                <p className="mb-4">Your payment has been successfully processed.</p>
                <div className="alert alert-info">
                  <strong>For any support, contact:</strong>
                  <br />
                  <a href="mailto:info@jgcjaipur.com" className="text-decoration-none">info@jgcjaipur.com</a>
                </div>
                <button
                  onClick={() => window.location.reload()}
                  className="btn btn-primary mt-3"
                >
                  Go Back to Home
                </button>
              </div>
            </div>
          </div>
        </>
        : <>
          <FrontPage />
        </>}

    </div>
  );
};

export default PaymentComponent;

import React, { useEffect, useState } from "react";
import VisitorForm from "./VisitorForm";
import axios from "axios";
import ExhibitorDashboardNavbar from "../exhibitor/exhibitor_dashboard/ExhibitorDashboardNavbar";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../common/useFetchData";

const ExhibitorOwner = () => {
  const navigate = useNavigate();
  const { showData, error } = useFetchData();


  return (
    <>
      <ExhibitorDashboardNavbar />
      {showData && showData.exhibitor_owner==="active" ? (
        <VisitorForm
          formHeader={"Exhibitor Owner Registration"}
          formName={"exhibitor_owner"}
          barcodeId={""}
        />
      ) : (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="alert alert-warning text-center" role="alert">
            <strong>Form is suspended</strong>
          </div>
        </div>
      )}
    </>
  );
};

export default ExhibitorOwner;

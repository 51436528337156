import React from "react";
import Printable from "./Printable";
import { useLocation } from "react-router-dom";

const FailPage = () => {
  const location = useLocation();
  const { visitorListData, visitorName, registrationDate } =
    location.state || {};
  console.log(
    "hello i am here",
    visitorListData,
    visitorName,
    registrationDate
  );
  return (
    <div
      className="container mt-4 alert alert-success text-center w-100 h-100"
      //   style={{ backgroundColor: "rgba(255, 0, 0, 0.4)" }}
    >
      {/* <div className="">
    <h1>Payment Successful</h1>
  </div> */}
      <div className="container h-100">
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-md-6">
            <div className="card shadow">
              <div className="card-body text-center">
                <h1 className="card-title mb-4">Payment Failed</h1>
                <div className="checkmark-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="150"
                    height="150"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="maroon"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="bi bi-x-circle"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <path d="M10 10l4 4m0-4l-4 4" />
                  </svg>
                </div>
                {visitorListData && (
                  <>
                    <div className="register-greetings">
                      <span>
                        {process.env.REACT_APP_ORGANIZER_NAME} Registration not confirmed
                      </span>
                      <span>
                        Dear <b>{visitorName}</b>,
                      </span>

                      <div className="register-details">
                        <span className="text-decoration-underline mb-4">
                          Here are your Registration details:{" "}
                        </span>
                        <span>
                          User Name:
                          <b> {visitorName}</b>
                        </span>
                        {/* <span>
                          Registration Number: <b> {visitorListData}</b>
                        </span> */}
                        <span>
                          Registration Date: <b> {registrationDate}</b>
                        </span>
                      </div>
                      <span className="mt-3">Best regards,</span>
                      <span>{process.env.REACT_APP_ORGANIZER_NAME}  Team</span>
                      {/* <Printable /> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailPage;

import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Logo from "../../assets/image/logo.png";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import CommonHeader from "../../common/CommonHeader";
import { NavDropdown } from "react-bootstrap";

function SubAdminNavbar() {
  const navigate = useNavigate();
  const [area, setArea] = useState(false)
  const handleLogout = () => {
    localStorage.removeItem("jff_token");
    localStorage.removeItem("category");
    localStorage.removeItem("permission");
    navigate("/adminvip/login");
  };
  return (
    <>
      <Navbar
        bg="dark"
        expand="lg"
        data-bs-theme="dark"
        className="bg-body-tertiary"
      >

        <CommonHeader redirection={"/adminvip/exhibitor-list"} />

        <Container>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              // style={{ maxHeight: "100px" }}
              navbarScroll
            >
              {" "}
              <Nav.Link onClick={() => navigate("/adminvip/exhibitor-list")}>
                Invited Buyer List
              </Nav.Link>

              <Nav.Link onClick={() => navigate("/hospitality/hotel-detail")}>
                Hotel
              </Nav.Link>
              <Nav.Link onClick={() => navigate("/cab-service/cab-detail")}>
                Cab Service
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate("/flight-service/flight-details")}
              >
                Flight Service
              </Nav.Link>
              <NavDropdown
                title="Setting"
                id="collasible-nav-dropdown"
                show={area}
                onMouseEnter={() => setArea(true)}
                onMouseLeave={() => setArea(false)}
              >
                <NavDropdown.Item
                  onClick={() => navigate("/adminvip/change-password")}>
                  Change Password
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleLogout()}>Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default SubAdminNavbar;

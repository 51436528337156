import React from 'react'

const Privacy = () => {
    return (
        <div class="page">
<div class="translations-content-container">
<h3>Privacy Policy</h3>
<p>This privacy policy governs your use of the website jgcjaipur.com (&quot;Website&quot;). The Website is designed to provide users with access to various services, including processing and managing payments through Razorpay&apos;s payment gateway, and other content or services offered through the Website.</p>
<p>What Information Does the Website Obtain and How Is It Used?</p>
<h5>User Provided Information</h5>
<p>The Website collects the information you provide when you visit, register, or use the services offered. Registration is optional, but please note that without registering, you may not be able to access certain features of the Website.</p>
<h5>When you register and use the Website, you may provide:</h5>
<p>1. Your name, email address, phone number, and other registration details.</p>
<p>2. Transaction-related information, such as purchases, responses to offers, or the use of features on the Website.</p>
<p>3. Information you provide when contacting customer support.</p>
<p>4. Payment details for purchases made through the Website, such as credit card or payment account information.</p>
<p>5. Information entered into the Website, such as contact information or service-related data.</p>
<p>We may use the information provided to contact you with important notices, updates, and marketing communications.</p>
<h5>Automatically Collected Information</h5>
<h6>The Website may collect certain information automatically when you visit it, including but not limited to:</h6>
<p>- Type of browser you use</p>
<p>- Your IP address</p>
<p>- Usage patterns and interactions on the Website</p>
<p>Does the Website Collect Real-Time Location Information? &nbsp;</p>
<p>The Website does not collect precise real-time location information.</p>
<p>Do Third Parties Have Access to Information Obtained by the Website? &nbsp;</p>
<p>We do not share your information with third parties, except in the cases outlined below:</p>
<p>1. As required by law, such as in response to a subpoena or legal process.</p>
<p>2. When necessary to protect the rights, safety, or security of others.</p>
<p>3. With trusted service providers acting on our behalf, provided they comply with this privacy policy.</p>
<p>If jgcjaipur.com is involved in a merger, acquisition, or sale, users will be informed of any changes in data handling via email or a prominent notice on our Website.</p>
<h5>What Are My Opt-Out Rights?</h5>
<p>You can opt out of information collection by discontinuing use of the Website. You may also opt out by contacting us at [<a data-fr-linked="true" href="mailto:info@jgcjaipur.com">info@jgcjaipur.com</a>](mailto:<a data-fr-linked="true" href="mailto:info@jgcjaipur.com">info@jgcjaipur.com</a>).</p>
<p>Data Retention Policy, Managing Your Information &nbsp;</p>
<p>User-provided data is retained for as long as you use the Website and for a reasonable period thereafter. Automatically collected data may be stored for up to 24 months. To request the deletion of your data, please contact us at [<a data-fr-linked="true" href="mailto:info@jgcjaipur.com">info@jgcjaipur.com</a>](mailto:<a data-fr-linked="true" href="mailto:info@jgcjaipur.com">info@jgcjaipur.com</a>). Some information may be necessary for the Website&rsquo;s functionality.</p>
<h5>Children</h5>
<p>We do not knowingly collect data from children under 13 years old. If a parent or guardian becomes aware of such collection, they can contact us at [<a data-fr-linked="true" href="mailto:info@jgcjaipur.com">info@jgcjaipur.com</a>](mailto:<a data-fr-linked="true" href="mailto:info@jgcjaipur.com">info@jgcjaipur.com</a>), and we will delete the information promptly.</p>
<h5>Security</h5>
<p>We are committed to protecting the confidentiality of your information. We use physical, electronic, and procedural safeguards to secure the data we process. However, no security system is 100% foolproof.</p>
<h5>Changes to This Policy</h5>
<p>This privacy policy may be updated periodically. Any changes will be posted here, and we may notify users via email. You are advised to review the policy regularly for any updates. Continued use of the Website implies acceptance of any changes.</p>
<h5>Your Consent</h5>
<p>By using the Website, you consent to the collection and processing of your information in accordance with this privacy policy. This may involve the transfer of data to countries outside of your jurisdiction, subject to local privacy regulations.</p>
<h5>Contact Us</h5>
<p>If you have any questions about this privacy policy, please contact us at [<a data-fr-linked="true" href="mailto:info@jgcjaipur.com">info@jgcjaipur.com</a>](mailto:<a data-fr-linked="true" href="mailto:info@jgcjaipur.com">info@jgcjaipur.com</a>).</p>
<p>---</p>
<p>This version has been modified to address the privacy policy needs for a website instead of a mobile application. All references to the application have been updated to reflect website usage.</p>
</div>
</div>
    )
}

export default Privacy

import React from 'react'

const Terms = () => {
  return (
    
<div>
<h3>Terms &amp; Conditions for jgcjaipur.com</h3>
<p>1. General Terms By accessing and using jgcjaipur.com, you agree to comply with these terms. We reserve the right to update or modify the terms at any time. You must review these changes regularly to ensure ongoing compliance.</p>
<p>2. Payment Terms All payments made on the Website are processed via Razorpay. By making a payment, you agree to the respective gateway&apos;s terms. We are not responsible for any errors or delays in payment processing. Refunds or disputes related to payments will be addressed based on the refund policy available at the time of payment.</p>
<p>3. User Responsibilities You are solely responsible for the content you upload or share on the website. Any content deemed inappropriate, illegal, or infringing on the rights of others may be removed at our discretion. Additionally, you are responsible for safeguarding your login credentials and preventing unauthorized access to your account.</p>
<p>4. Modification of Services We may modify or discontinue any part of the services offered on jgcjaipur.com without prior notice. We are not liable for any interruptions in service due to updates or maintenance.</p>
<p>5. Data Privacy By using our services, you consent to the collection and processing of personal data as outlined in our Privacy Policy. We employ secure measures to protect your data, but users are also encouraged to practice caution while sharing personal information.</p>
<p>6. Limitation of Liability jgcjaipur.com is not liable for any direct, indirect, or incidental damages arising from your use of the website or related services. We are also not responsible for any third-party actions, including payment processors.</p>
<p>7. Governing Law These terms are governed by and construed in accordance with the laws of India. Any disputes arising from these terms shall be subject to the jurisdiction of the courts in Jaipur, India.</p>
<p>8. Contact Information For any queries or concerns regarding these terms, please contact us at <a data-fr-linked="true" href="mailto:info@jgcjaipur.com">info@jgcjaipur.com</a>.</p>
        </div>
  )
}

export default Terms

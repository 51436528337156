import React, { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import Header from "../dashboard/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoginRedirection from "../../Service/LoginRedirection";
import { message } from "antd";
import TokenUndefined from "../../Service/TokenUndefined";

const DashBoard = () => {
  const [visitorRequest, setVisitorRequest] = useState("");
  const [visitorHold, setVisitorHold] = useState("");
  const [visitorReject, setVisitorReject] = useState("");
  const [agentUnapproved, setAgentUnapproved] = useState("");
  const [agentApproved, setAgentApproved] = useState("");
  const [exhibitorOwner, setExhibitorOwner] = useState("");
  const [exhibitorStaff, setExhibitorStaff] = useState("");
  const [exhibitorFamily, setExhibitorFamily] = useState("");
  const [exhibitorModel, setExhibitorModel] = useState("");

  const [vendorOwner, setVendorOwner] = useState("");
  const [vendorStaff, setVendorStaff] = useState("");
  const [vipRequest, setVipRequest] = useState("");
  const [vipApproved, setVipApproved] = useState("");
  const [vipRejected, setVipRejected] = useState("");
  const [preApprovedVisitor, setPreApprovedVisitor] = useState("")
  const [allExhibitor, setAllExhibitor] = useState("");
  const [allVendor, setAllVendor] = useState("");
  const [ofaVisitor, setOfaVisitor] = useState("");
  const [nfaVisitor, setNfaVisitor] = useState("");
  const [ofaBookedVisitor, setOfaBookedVisitor] = useState("");
  const [nfaBookedVisitor, setNfaBookedVisitor] = useState("");
  const [VisitorApproved, setVisitorApproved] = useState("");
  const [agentPreApproved, setAgentPreApproved] = useState("")
  const [agenthold, setAgenthold] = useState("")
  const [agentreject, setAgentreject] = useState("")
  const [flights_data, set_flights_data] = useState([])

  const [spinner, setSpinner] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/exhibitor-dashboard`, {
        headers: {
          token: localStorage.getItem("jff_token"),
        },
      })
      .then((res) => {
        console.log("asadsadssadsdasd", res.data);
        setVisitorRequest(res.data.visitorRequest);
        setVisitorHold(res.data.visitorHold);
        setVisitorReject(res.data.visitorReject);
        setAgentUnapproved(res.data.agentUnapproved);
        setExhibitorOwner(res.data.exhibitorOwner);
        setExhibitorStaff(res.data.exhibitorStaff);
        setVendorOwner(res.data.vendorOwner);
        setAgentApproved(res.data.agentApproved);
        setVendorStaff(res.data.vendorStaff);
        setVipRequest(res.data.vipRequest);
        setVipApproved(res.data.vipApproved);
        setVipRejected(res.data.vipRejected);
        setAllExhibitor(res.data.allExhibitor);
        setAllVendor(res.data.allVendor);
        setOfaVisitor(res.data.ofaVisitor);
        setNfaVisitor(res.data.nfaVisitor);
        setOfaBookedVisitor(res.data.ofaBookedVisitor);
        setNfaBookedVisitor(res.data.nfaBookedVisitor);
        setVisitorApproved(res.data.acceptedVisitor);
        setExhibitorFamily(res.data?.exhibitorFamily)
        setExhibitorModel(res.data?.exhibitorModel)
        setPreApprovedVisitor(res.data?.preapprovedVisitor)
        setAgentPreApproved(res?.data?.agentPreApproved)
        setAgenthold(res.data.agenthold)
        setAgentreject(res.data.agentreject)
        set_flights_data(res.data.flight_data)

        setSpinner(false);
      })
      .catch((err) => {
        // LoginRedirection("/superadmin/login");
        console.log("aadssadsdsda", err);
        if (err.response.status === 401) {
          navigate("/superadmin/login");
          localStorage.removeItem("jff_token");
          localStorage.removeItem("category");
          localStorage.removeItem("permission");
          localStorage.removeItem("user_id");
          localStorage.removeItem("type_of_user");
        }
      });
  }, []);

  const downloadAllList = () => {
    TokenUndefined("superadmin/login");
    axios
      .get(`${process.env.REACT_APP_API_END_POINT}/download-all-data`, {
        headers: {
          token: localStorage.getItem("jff_token"),
        },
      })
      .then((res) => {
        LoginRedirection(res, "superadmin/login");
        message.success("Success");
        const link = `${process.env.REACT_APP_API_END_POINT}/download-all-data`;
        window.location.href = link;
      })
      .catch((err) => {
        // LoginRedirection("/superadmin/login");
        if (err.response.status === 401) {
          navigate("/superadmin/login");
          localStorage.removeItem("jff_token");
          localStorage.removeItem("category");
          localStorage.removeItem("permission");
          localStorage.removeItem("user_id");
          localStorage.removeItem("type_of_user");
        }
      });
  };
  return (
    <>
      <Header />
      {spinner ? (
        <>
          <div className="content-height d-flex align-items-center justify-content-center">
            <div
              className="spinner-border text-center"
              style={{ width: " 3rem", height: " 3rem" }}
              role="status"
            ></div>
          </div>
        </>
      ) : (
        <div className="content-height">
          <div className="text-center">
            <h2 className="form-heading">Super Admin DashBoard</h2>
          </div>

          <div>
            <button
              className="btn btn-outline-success ms-3"
              type="submit"
              onClick={() => downloadAllList()}
            >
              Download All List
            </button>
          </div>
          <div className="container text-center my-2">
            <div className="mb-5 p-5">
              <div className="row" style={{ gap: "20px 0px" }}>
                {/* <div className="col-lg-6">
                <div className="p-3 border">
                  <p className="fw-bold fs-4 my-1">VIP Guest (DAY 1)</p>
                  <div className="container text-center my-2">
                    <div className="row" style={{ backgroundColor: "#3D4156" }}>
                      <div className="col border border-white text-white">
                        Request{" "}
                      </div>
                      <div className="col border border-white text-white">
                        Invited
                      </div>
                      <div className="col border border-white text-white">
                        Pending
                      </div>
                    </div>
                    <div className="row" style={{ backgroundColor: "#E0E0E0" }}>
                      <div className="col border border-white">0</div>
                      <div className="col border border-white">1</div>
                      <div className="col border border-white">2</div>
                    </div>
                  </div>
                </div>
              </div> */}
                {/* <div className="col-lg-6">
                <div className="p-3 border">
                  <p className="fw-bold fs-4 my-1">VIP Guest (Day 2)</p>
                  <div className="container text-center my-2">
                    <div className="row" style={{ backgroundColor: "#3D4156" }}>
                      <div className="col border border-white text-white">
                        Request{" "}
                      </div>
                      <div className="col border border-white text-white">
                        Invited
                      </div>
                      <div className="col border border-white text-white">
                        Pending
                      </div>
                    </div>
                    <div className="row" style={{ backgroundColor: "#E0E0E0" }}>
                      <div className="col border border-white">0</div>
                      <div className="col border border-white">1</div>
                      <div className="col border border-white">2</div>
                    </div>
                  </div>
                </div>
              </div> */}

                {/* <div className="col-lg-6">
                <div className="p-3 border">
                  <p className="fw-bold fs-4 my-1">Exhibitor</p>
                  <div className="container text-center my-2">
                    <div className="row" style={{ backgroundColor: "#3D4156" }}>
                      <div className="col border border-white text-white">
                        Registered Owner
                      </div>
                      <div className="col border border-white text-white">
                        Approved Owners{" "}
                      </div>
                      <div className="col border border-white text-white">
                        Pending Owners
                      </div>
                    </div>
                    <div className="row" style={{ backgroundColor: "#E0E0E0" }}>
                      <div className="col border border-white">
                        {" "}
                        {exhibitorOwner ? exhibitorOwner : "0"}
                      </div>
                      <div className="col border border-white">1</div>
                      <div className="col border border-white">2</div>
                    </div>
                  </div>
                </div>
              </div> */}
                <div className="col-lg-12">
                  <div className="p-3 border">
                    <p className="fw-bold fs-4 my-1">Exhibitor</p>
                    <div className="container text-center my-2">
                      <div
                        className="row"
                        style={{ backgroundColor: "#3D4156" }}
                      >
                        <div className="col border border-white text-white" >
                          All Exhibitor
                        </div>
                        <div className="col border border-white text-white" >
                          Owner
                        </div>
                        <div className="col border border-white text-white" >
                          Staff
                        </div>

                        <div className="col border border-white text-white">
                          Family
                        </div>
                        <div className="col border border-white text-white">
                          Model
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ backgroundColor: "#E0E0E0" }}
                      >
                        <div className="col border border-white" onClick={() => navigate("/superadmin/exhibitor-list")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {allExhibitor ? allExhibitor : "0"}
                        </div>
                        <div className="col border border-white" onClick={() => navigate("/superadmin/exhibitor-list")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {exhibitorOwner ? exhibitorOwner : "0"}
                        </div>
                        <div className="col border border-white" onClick={() => navigate("/superadmin/exhibitor-list")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {exhibitorStaff ? exhibitorStaff : "0"}
                        </div>
                        <div className="col border border-white" onClick={() => navigate("/superadmin/exhibitor-list")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {exhibitorFamily ? exhibitorFamily : "0"}
                        </div>
                        <div className="col border border-white" onClick={() => navigate("/superadmin/exhibitor-list")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {exhibitorModel ? exhibitorModel : "0"}
                        </div>
                        {/*
                         <div className="col border border-white">1</div>
                      <div className="col border border-white">2</div> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-6">
                  <div className="p-3 border">
                    <p className="fw-bold fs-4 my-1">Vendor</p>
                    <div className="container text-center my-2">
                      <div
                        className="row"
                        style={{ backgroundColor: "#3D4156" }}
                      >
                        {" "}
                        <div className="col border border-white text-white">
                          All Vendor
                        </div>
                        <div className="col border border-white text-white">
                          Registered Owner
                        </div>
                        <div className="col border border-white text-white">
                        Approved Owners{" "}
                      </div>
                      <div className="col border border-white text-white">
                        Pending Owners
                      </div>
                        <div className="col border border-white text-white">
                          Registered Staff
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ backgroundColor: "#E0E0E0" }}
                      >
                        <div className="col border border-white" onClick={()=>navigate("/superadmin/vender-list")} style={{cursor:'pointer'}}>
                          {" "}
                          {allVendor ? allVendor : "0"}
                        </div>
                        <div className="col border border-white" onClick={()=>navigate("/superadmin/vender-list")} style={{cursor:'pointer'}}>
                          {" "}
                          {vendorOwner ? vendorOwner : "0"}
                        </div>
                        <div className="col border border-white" onClick={()=>navigate("/superadmin/vender-list")} style={{cursor:'pointer'}}>
                          {" "}
                          {vendorStaff ? vendorStaff : "0"}
                        </div>
                        <div className="col border border-white">1</div>
                      <div className="col border border-white">2</div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-12">
                  <div className="p-3 border">
                    <p className="fw-bold fs-4 my-1">Visitor</p>
                    <div className="container text-center my-2">
                      <div
                        className="row"
                        style={{ backgroundColor: "#3D4156" }}
                      >
                        <div className="col border border-white text-white">
                          Visitor Request
                        </div>
                        <div className="col border border-white text-white">
                          Pre Approved
                        </div>
                        <div className="col border border-white text-white">
                          Accepted
                        </div>
                        <div className="col border border-white text-white">
                          Hold
                        </div>
                        <div className="col border border-white text-white">
                          Reject
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ backgroundColor: "#E0E0E0" }}
                      >
                        <div className="col border border-white" onClick={() => navigate("/superadmin/vis-req-li")} style={{ cursor: 'pointer' }}>
                          {visitorRequest ? visitorRequest : "0"}
                        </div>
                        <div className="col border border-white" onClick={() => navigate("/superadmin/acc-vis-li")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {preApprovedVisitor ? preApprovedVisitor : "0"}
                        </div>
                        <div className="col border border-white" onClick={() => navigate("/superadmin/acc-vis-li")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {VisitorApproved ? VisitorApproved : "0"}
                        </div>
                        <div className="col border border-white" onClick={() => navigate("/superadmin/hol-vis-li")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {visitorHold ? visitorHold : "0"}
                        </div>
                        <div className="col border border-white" onClick={() => navigate("/superadmin/rej-vis-li")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {visitorReject ? visitorReject : "0"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="p-3 border h-100" >
                    <p className="fw-bold fs-4 my-1">Agent</p>
                    <div className="container text-center my-2">
                      <div
                        className="row"
                        style={{ backgroundColor: "#3D4156" }}
                      >
                        <div className="col border border-white text-white">
                          Registered
                        </div>
                        <div className="col border border-white text-white">
                          Pre Approved
                        </div>
                        <div className="col border border-white text-white">
                          Approved
                        </div>
                        {/* <div className="col border border-white text-white">
                          Hold
                        </div>
                        <div className="col border border-white text-white">
                          Reject
                        </div> */}
                      </div>
                      <div
                        className="row"
                        style={{ backgroundColor: "#E0E0E0" }}
                      >
                        {/* <div className="col border border-white">
                          {" "}
                          {visitorReject ? visitorReject : "0"}
                        </div> */}
                        <div className="col border border-white" onClick={() => navigate("/superadmin/age-li")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {agentUnapproved ? agentUnapproved : "0"}
                        </div>
                        <div className="col border border-white" onClick={() => navigate("/superadmin/app-age-li")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {agentPreApproved ? agentPreApproved : "0"}
                        </div>
                        <div className="col border border-white" onClick={() => navigate("/superadmin/app-age-li")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {agentApproved ? agentApproved : "0"}
                        </div>  
                            {/* <div className="col border border-white" onClick={() => navigate("/superadmin/app-age-li")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {agenthold ? agenthold : "0"}
                        </div>
                        <div className="col border border-white" onClick={() => navigate("/superadmin/app-age-li")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {agentreject ? agentreject : "0"}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="p-3 border">
                    <p className="fw-bold fs-4 my-1">Buyer</p>
                    <div className="container text-center my-2">
                      <div
                        className="row"
                        style={{ backgroundColor: "#3D4156" }}
                      >
                        {" "}
                        <div className="col border border-white text-white">
                          Buyer Request
                        </div>
                        <div className="col border border-white text-white">
                          Buyer Approved
                        </div>
                        {/* <div className="col border border-white text-white">
                        Approved Owners{" "}
                      </div>
                      <div className="col border border-white text-white">
                        Pending Owners
                      </div> */}
                        <div className="col border border-white text-white">
                          Buyer Rejected
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{ backgroundColor: "#E0E0E0" }}
                      >
                        <div className="col border border-white" onClick={() => navigate("/superadmin/vip-guest")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {vipRequest ? vipRequest : "0"}
                        </div>
                        <div className="col border border-white" onClick={() => navigate("/superadmin/invited-vip-guest")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {vipApproved ? vipApproved : "0"}
                        </div>
                        <div className="col border border-white" onClick={() => navigate("/superadmin/recycled-vip")} style={{ cursor: 'pointer' }}>
                          {" "}
                          {vipRejected ? vipRejected : "0"}
                        </div>
                        {/* <div className="col border border-white">1</div>
                      <div className="col border border-white">2</div> */}
                      </div>
                    </div>
                 {flights_data.length>0 &&   <div className="d-flex flex-wrap">
                      <div className="p-3 border col-lg-12">
                        <p className="fw-bold fs-4 my-1">Flights</p>
                        <div className="container text-center my-2">
                          <div className="row">
                            {flights_data.map(flight => (
                              <div className="col-lg-6 col-md-12 p-2" key={flight._id}>
                                <div className="p-3 border">
                                  <p className="fw-bold fs-4 my-1">{flight.name}</p>
                                  <div className="container text-center my-2">
                                    <div className="row" style={{ backgroundColor: "#3D4156" }}>
                                      <div className="col border border-white text-white">
                                        Assigned
                                      </div>
                                      <div className="col border border-white text-white">
                                        Booked
                                      </div>
                                    </div>
                                    <div className="row" style={{ backgroundColor: "#E0E0E0" }}>
                                      <div className="col border border-white">
                                        {flight.totalVisitors}
                                      </div>
                                      <div className="col border border-white">
                                        {flight.bookingVisitors}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>}

                  </div>
                </div>

                {/* <div className="col-lg-6">
                <div className="p-3 border">
                  <p className="fw-bold fs-4 my-1">Vendor</p>
                  <div className="container text-center my-2">
                    <div className="row" style={{ backgroundColor: "#3D4156" }}>
                      <div className="col border border-white text-white">
                        Registered Staff
                      </div>
                      <div className="col border border-white text-white">
                        Approved Staff
                      </div>
                      <div className="col border border-white text-white">
                        Pending Staff
                      </div>
                    </div>
                    <div className="row" style={{ backgroundColor: "#E0E0E0" }}>
                      <div className="col border border-white">
                        {" "}
                        {vendorStaff ? vendorStaff : "0"}
                      </div>
                      <div className="col border border-white">1</div>
                      <div className="col border border-white">2</div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default DashBoard;

import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchData = () => {
  const [showData, setShowData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_END_POINT}/get-registration-visibility`);
        setShowData(response.data.data);
      } catch (err) {
        setError(err);
      }
    };

    fetchData();
  }, []);

  return { showData, error };
};

export default useFetchData;

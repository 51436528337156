import React, { useEffect, useState } from "react";
import axios from "axios";
import MainLogo from "../../../src/assets/image/main-logo.png";
import { useLocation } from "react-router-dom";
import Footer from "../superAdmin/footer/Footer";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import TokenUndefined from "../Service/TokenUndefined";
import { message } from "antd";
import FormatChecker from "../Service/FormatChecker";
import CommonFileUploader from "../../common/CommonFileUploader";

function VisitorForm({
  formHeader,
  formName,
  editStatus,
  companyType,
  edit_name,
  edit_contact,
  edit_phone,
  edit_email,
  id,
  barcode,
  barcodeId,
}) {
  let editName = edit_name === undefined ? "" : edit_name;
  let editContact = edit_contact === undefined ? "" : edit_contact;
  let editPhone = edit_phone === undefined ? "" : edit_phone;
  let editEmail = edit_email === undefined ? "" : edit_email;

  console.log(
    "sdasdadadadadads0",
    editName,
    editContact,
    editPhone,
    editEmail,
    companyType
  );
  const navigate = useNavigate();

  let currentLocation = useLocation().pathname;

  const designationArray = ["Owner", "Representative "];
  const visitingAsArray = [
    "Wholesaler",
    "Manufacturer",
    "Retailer",
    "Chain Store   ",
    "Agent/Broker",
  ];
  const [number, setNumber] = useState(editPhone);
  const [visitorName, setVisitorName] = useState(editContact);
  const [designation, setDesignation] = useState("");
  const [visitingCategory, setVisitingCategory] = useState("");
  const [visitingID, setVisitingID] = useState("");
  const [visiting_as, setVisiting_as] = useState("");
  const [yourCompany, setYourCompany] = useState();
  const [comapnyList, setComapnyList] = useState([]);
  const [invitedByCompany, setInvitedByCompany] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState(editEmail);
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddressTwo, setstreetAddressTwo] = useState("");
  const [country, setCountry] = useState("India");
  const [hearAbout, setHearAbout] = useState("");
  const [photo, setPhoto] = useState();
  const [idProofFront, setIDProofFront] = useState();
  const [idProofBack, setIDProofBack] = useState();
  const [invitedCompanyName, setInvitedCompanyName] = useState(editName);
  const [visitingDay, setVisitingDay] = useState("");
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [exhibitorName, setExhibitorName] = useState("");
  const [photoStatus, setPhotoStatus] = useState(false);
  const [idFrontStatus, setIdFrontStatus] = useState(false);
  const [idBackStatus, setIdBackStatus] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [gst_number, setGst_number] = useState("");
  const [showError, setShowError] = useState("");
  const [reloadShow, setReloadShow] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const handleMobileNoChange = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");
    setNumber(numericValue);

    if (
      numericValue.length === 10 &&
      (formName === "exhibitor_vip" ||
        formName === "exhibitor_owner" ||
        formName === "exhibitor_staff")
    ) {
      axios
        .get(
          `${process.env.REACT_APP_API_END_POINT}/check-is-number/${numericValue}/${formName}`
        )
        .then((resData) => {
          setDuplicateError(resData.data.status);
        });
    }
  };

  let formJson = {
    visitor: [
      "mobile",
      "visitor_name",
      "designation",
      "visiting_as",
      // "invited_company",
      "email",
      "street_add_line1",
      "street_add_line2",
      "city",
      "state",
      "zipcode",
      "country",
      "hduhear",
      "photo",
      "id_front",
      "id_back",
      // "visiting_day",
    ],
    exhibitor_owner: [
      "mobile",
      "visitor_name",
      // "designation",
      // "visiting_as",
      // "invited_company",
      // "email",
      // "street_add_line1",
      // "street_add_line2",
      // "city",
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",
      "photo",
      // "id_front",
      // "id_back",
      // "visiting_day",
    ],
    exhibitor_staff: [
      "mobile",
      "visitor_name",
      // "designation",
      // "visiting_as",
      // "invited_company",
      // "email",
      // "street_add_line1",
      // "street_add_line2",
      // "city",
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",
      "photo",
      // "id_front",
      // "id_back",
      // "visiting_day",
    ],
    exhibitor_modal: ["mobile", "visitor_name", "photo", "id_front", "id_back"],
    exhibitor_family: [
      "mobile",
      "visitor_name",
      "photo",
      "id_front",
      "id_back",
    ],
    exhibitor_vip: [
      "mobile",
      "visitor_name",
      "designation",
      // "visiting_as",
      // "invited_company",
      "email",
      "street_add_line1",
      "street_add_line2",
      "city",
      "gst_number",
      'country_array',
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",
      // "photo",
      // "id_front",
      // "id_back",
      // "visiting_day",
      "company_name",
    ],
    vendor_owner: [
      "mobile",
      "visitor_name",
      // "designation",
      // "visiting_as",
      // "invited_company",
      // "email",
      // "street_add_line1",
      // "street_add_line2",
      // "city",
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",
      "photo",
      "id_front",
      "id_back",
      // "visiting_day",
    ],
    edit_update: [
      "visitor_name",
      "mobile",
      "invited_company",
      "email",
      "photo",
      "id_front",
      "id_back",
    ],
    edit_form_update: [
      "visitor_name",
      "mobile",
      "invited_company",
      "email",
      "photo",
      "id_front",
      "id_back",
    ],
    vendor_staff: [
      "mobile",
      "visitor_name",
      // "designation",
      // "visiting_as",
      // "invited_company",
      // "email",
      // "street_add_line1",
      // "street_add_line2",
      // "city",
      // "state",
      // "zipcode",
      // "country",
      // "hduhear",
      "photo",
      "id_front",
      "id_back",
      // "visiting_day",
    ],
  };


  const countries = [

    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, The Democratic Republic of The",
    "Cook Islands",
    "Costa Rica",
    "Cote D'ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-bissau",
    "Guyana",
    "Haiti",
    "Heard Island and Mcdonald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, The Former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territory, Occupied",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Pierre and Miquelon",
    "Saint Vincent and The Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and The South Sandwich Islands",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  // // Transforming the list into the required format
  // const countryArray = countries.map(country => ({
  //   value: country,
  //   label: country
  // }));
  function handlePhoto(e) {
    let dataStatus = FormatChecker(e.target.files[0]);
    setPhotoStatus(dataStatus);
    if (!dataStatus) {
      setPhoto(e.target.files[0]);
    } else {
      message.error("please upload in the given format");
    }
  }
  const handleName = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setVisitorName(alphabetOnlyValue);
  };
  const handleCity = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setCity(alphabetOnlyValue);
  };
  const handleState = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setState(alphabetOnlyValue);
  };
  const handleCountry = (e) => {
    const newValue = e.target.value;
    const alphabetOnlyValue = newValue.replace(/[^A-Za-z ]/g, "");
    setCountry(alphabetOnlyValue);
  };

  const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  function checkRegex(input) {
    return regex.test(input);
  }

  function convertToUpperCase(input) {
    return input.replace(/[a-z]/gi, (char) => char.toUpperCase());
  }

  const handleGstNumberChange = (e) => {
    setGst_number(convertToUpperCase(e.target.value));
    const value = e.target.value;
    if (checkRegex(value)) {
      console.log("asdasdasd", e.target.value);
      setShowError("");
    } else {
      setShowError("Provide Valid GSTIN");
    }
  };

  useEffect(() => {
    console.log("hello i am here to see the test daeeed", gst_number.length);

    if (gst_number.length === 15 && checkRegex(gst_number)) {
      // setReloadShow(true);
      // ;

      axios
        .get(
          `${process.env.REACT_APP_API_END_POINT}/check-gst-validation/${gst_number}`
        )
        .then((resData) => {
          // setReloadShow(false);
          // console.log(
          //   "hello i am here to see the test daeeed",
          //   resData.data.message,
          //   resData.data.data.lgnm,
          //   resData.data.data.pradr.adr
          // );
          // if (resData.data.message === "GSTIN  found.") {
          console.log("gst find or not", resData.data);
          if (resData.data.flag === false) {
            setShowError(resData.data.message);
            return;
          }
          setShowError("");
          setCompanyName(resData.data.company);
          setStreetAddress(resData.data.address);
          setCity(resData.data.city);
          // }
        }).catch((err) => {
          console.log("gst fetch validation ")
          setShowError("Facing Problem While Fetching")
        })
    }
  }, [gst_number]);

  const handlePostalCode = (e) => {
    const input = e.target.value;
    const numericValue = input.replace(/\D/g, "");
    setPostalCode(numericValue);
  };
  // useEffect(() => {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_END_POINT}/visitor-category`,
  //       {
  //         id: visitingID,
  //       },
  //       {
  //         headers: {
  //           token: localStorage.getItem("token"),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log("get-visitor", res.data.data);
  //       setAllCategory(res.data.data);
  //     });
  // }, []);

  function handleIDFront(e) {
    let dataStatus = FormatChecker(e.target.files[0]);
    setIdFrontStatus(dataStatus);
    if (!dataStatus) {
      setIDProofFront(e.target.files[0]);
    } else {
      message.error("please upload in the given format");
    }
  }
  function handleIDBack(e) {
    let dataStatus = FormatChecker(e.target.files[0]);
    setIdBackStatus(dataStatus);
    if (!dataStatus) {
      setIDProofBack(e.target.files[0]);
    } else {
      message.error("please upload in the given format");
    }
  }

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!emailPattern.test(value)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  useEffect(() => {
    emptyField();
  }, [reload]);

  useEffect(() => {
    if (
      formName === "vendor_owner" ||
      formName === "vendor_staff" ||
      companyType === "vendor"
    ) {
      axios
        .get(`${process.env.REACT_APP_API_END_POINT}/get-vendor-list`)
        .then((res) => {
          // console.log("assasdasaadads", res.data.data);
          setComapnyList(res.data.data);
        });
    } else {
      if (formName !== "vendor_staff" && formName !== "vendor_owner") {
        axios
          .get(`${process.env.REACT_APP_API_END_POINT}/get-exhibitor-list`, {
            headers: {
              token: localStorage.getItem("jff_token"),
            },
          })
          .then((res) => {
            // console.log("assasdasaadads", res.data.data);
            setInvitedByCompany(res?.data?.data);
          });
      }
    }
  }, [reload]);

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    if (formName !== "vendor_staff" && formName !== "vendor_owner") {
      console.log(
        "abcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadaddabcdafasffafdadadd",
        formName !== "vendor_staff" && formName !== "vendor_owner",
        formName
      );
      TokenUndefined("exhibitor/login");
    }

    if (formName !== "vendor_staff" && formName !== "vendor_owner") {
      axios
        .post(`${process.env.REACT_APP_API_END_POINT}/get-exhibitor-name`, "", {
          headers: {
            token: localStorage.getItem("jff_token"),
          },
        })
        .then((res) => {
          // LoginRedirection(res, "exhibitor/login");
          setExhibitorName(res.data.data);
        })
        .catch((err) => {
          // LoginRedirection("/superadmin/login");

          // if (formName !== "vendor_staff" || formName !== "vendor_owner") {
          if (err.response.status === 401) {
            navigate("/exhibitor/login");
            localStorage.removeItem("jff_token");
            localStorage.removeItem("category");
            localStorage.removeItem("permission");
            localStorage.removeItem("user_id");
            localStorage.removeItem("type_of_user");
          }
          // }
        });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log("hello i am here", formName);
    setIsLoading(true);

    const dataNeedTOCheck = await axios.get(
      `${process.env.REACT_APP_API_END_POINT}/form-status-exhibitor`,
      {
        headers: {
          token: localStorage.getItem("jff_token"),
        },
      }
    );
    if (formName === "exhibitor_owner" && !dataNeedTOCheck.data.ownerstatus) {
      message.error("The limit to add new members has exhausted");
      setIsLoading(false);

      return;
    }
    if (formName === "exhibitor_staff" && !dataNeedTOCheck.data.staffstatus) {
      message.error("The limit to add new members has exhausted");
      setIsLoading(false);

      return;
    }
    if (formName === "exhibitor_vip" && !dataNeedTOCheck.data.vipstatus) {
      message.error("The limit to add new members has exhausted");
      setIsLoading(false);

      return;
    }
    if (formName === "exhibitor_famil" && !dataNeedTOCheck.data.familyStatus) {
      message.error("The limit to add new members has exhausted");
      setIsLoading(false);

      return;
    }
    if (formName === "exhibitor_modal" && !dataNeedTOCheck.data.modelStatus) {
      message.error("The limit to add new members has exhausted");
      setIsLoading(false);

      return;
    }

    //  formName !== "exhibitor_staff" &&
    // formName !== "exhibitor_vip"
    //  .then((res) => {
    //    console.log(res.data);
    //    if (res.data.staffstatus) {
    //      navigate("/exhibitor/exhibitor-staff-registration");
    //    } else {
    //      message.error("The limit to add new members has exhausted");
    //    }
    //  });

    if (country === "India" && number.length !== 10) {
      message.error("Please Enter 10 Digit Mobile Number");
      setIsLoading(false);
      return;
    }
    //  else if (emailError && formName !== "exhibitor_vip") {
    //   message.error("Invalid email format");
    //   setIsLoading(false);
    //   return;
    // } 
    else if (!companyName && formName === "exhibitor_vip") {
      message.error("Company Name is required");
      setIsLoading(false);
    } else if (!designation && formName === "exhibitor_vip") {
      message.error("Designation is required");
      setIsLoading(false);
    } else if (!city && formName === "exhibitor_vip") {
      message.error("City is required");
      setIsLoading(false);
    } else if (!country && formName === "exhibitor_vip") {
      message.error("Country is required");
      setIsLoading(false);
    } else if (!photo && formName !== "exhibitor_vip") {
      message.error("Photo is required");
      setIsLoading(false);
    } else if (
      !idProofFront &&
      formName !== "exhibitor_owner" &&
      formName !== "exhibitor_staff" &&
      formName !== "exhibitor_vip"
    ) {
      message.error("ID Proof Front is required");
      setIsLoading(false);
    } else if (
      !idProofBack &&
      formName !== "exhibitor_owner" &&
      formName !== "exhibitor_staff" &&
      formName !== "exhibitor_vip"
    ) {
      message.error("ID Proof Back is required");
      setIsLoading(false);
    } else {
      if (editStatus) {
        // if (number.length !== 10) {
        //   message.error("Please Enter 10 Digit Mobile Number");
        //   setIsLoading(false);
        //   return;
        // }
        // if (emailError) {
        //   message.error("Invalid email format");
        //   setIsLoading(false);
        //   return;
        // }
        e.preventDefault();
        const formData = new FormData();
        formData.append("visitor_name", visitorName);
        formData.append("email", email);
        formData.append("company_name", companyName);
        formData.append("mobile_number", number);
        formData.append("invited_exhibitor_id", invitedByCompany);
        formData.append("photo", photo);
        formData.append("id_proof_front", idProofFront);
        formData.append("id_proof_back", idProofBack);
        formData.append("id", id);

        axios
          .post(
            `${process.env.REACT_APP_API_END_POINT}/update-visitor`,
            formData,
            {
              headers: {
                token: `${localStorage.getItem("jff_token")}`,
                barcode,
              },
            }
          )
          .then((res) => {
            if (res.data.messageData) {
              message.error(res.data.messageData);
              setIsLoading(false);
              emptyField();
            } else {
              window.location.reload(true);
              // setReload(!reload)
              // message.success("Form Submit Successfully");
              window.alert("Form Submit Successfully");
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.error("Axios Error:", error);
          });
      } else {
        // {
        if (!photo && !idProofFront && !idProofBack) {
          axios
            .post(
              `${process.env.REACT_APP_API_END_POINT}/add-visitor`,
              {
                mobile_number: number,
                visitor_name: visitorName,
                designation: designation,
                visiting_as: visiting_as,
                firm_id: yourCompany,
                invited_exhibitor_id: invitedByCompany,
                email: email,
                company_name: companyName,
                street_address: streetAddress,
                street_address_two: streetAddressTwo,
                city: city,
                gst_number,
                state: state,
                postal_code: postalCode,
                country: country,
                hear_about_jff: hearAbout,
                category: formName,
                visiting_day: visitingDay,
                visitor_category_id: visitingID,
                barcodeId,
              },
              {
                headers: {
                  token: localStorage.getItem("jff_token"),
                },
              }
            )
            .then((res) => {
              // setReload(!reload)
              // window.location.reload(true);
              // message.success("Form Submit Successfully");
              if (res.data.messageData) {
                message.error(res.data.messageData);
                setIsLoading(false);
                emptyField();
              } else {
                window.alert("Form Submit Successfully");
                setIsLoading(false);
                window.location.reload(true);
                if (formName === "exhibitor_owner") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/owner-list");
                } else if (formName === "exhibitor_staff") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/staff-list");
                } else if (formName === "exhibitor_modal") {
                  navigate("/exhibitor/modal-list");
                } else if (formName === "exhibitor_family") {
                  navigate("/exhibitor/family-list");
                } else if (formName === "exhibitor_vip") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/vip-guest-requrest-list");
                }
              }
            })
            .catch((err) => {
              // LoginRedirection("/superadmin/login");
              if (err.response.status === 401) {
                navigate("/superadmin/login");
                localStorage.removeItem("jff_token");
                localStorage.removeItem("category");
                localStorage.removeItem("permission");
                localStorage.removeItem("user_id");
                localStorage.removeItem("type_of_user");
              }
            });
        } else {
          const formData = new FormData();
          formData.append("mobile_number", number);
          formData.append("visitor_name", visitorName);
          formData.append("designation", designation);
          formData.append("visiting_as", visiting_as);
          formData.append("firm_id", yourCompany);
          formData.append("invited_exhibitor_id", invitedByCompany);
          formData.append("email", email);
          formData.append("company_name", companyName);
          formData.append("street_address", streetAddress);
          formData.append("street_address_two", streetAddressTwo);
          formData.append("city", city);
          formData.append("state", state);
          formData.append("postal_code", postalCode);
          formData.append("country", country);
          formData.append("hear_about_jff", hearAbout);
          formData.append("photo", photo);
          formData.append("id_proof_front", idProofFront);
          formData.append("id_proof_back", idProofBack);
          formData.append("category", formName);
          formData.append("visiting_day", visitingDay);
          formData.append("visitor_category_id", visitingID);
          formData.append("barcodeId", barcodeId);
          axios
            .post(
              `${process.env.REACT_APP_API_END_POINT}/add-visitor`,
              formData,
              {
                headers: {
                  token: localStorage.getItem("jff_token"),
                },
              }
            )
            .then((res) => {
              // setReload(!reload)
              // window.location.reload(true);
              // message.success("Form Submit Successfully");
              emptyField();
              if (res.data.messageData) {
                alert(res.data.messageData);
                window.location.reload(true);
                setIsLoading(false);
              } else {
                window.alert("Form Submit Successfully");
                setIsLoading(false);
                window.location.reload(true);
                // console.log("anisdsd", formName);
                if (formName === "exhibitor_owner") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/owner-list");
                } else if (formName === "exhibitor_staff") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/staff-list");
                } else if (formName === "exhibitor_modal") {
                  navigate("/exhibitor/modal-list");
                } else if (formName === "exhibitor_family") {
                  navigate("/exhibitor/family-list");
                } else if (formName === "exhibitor_vip") {
                  // console.log("anisdsdif", formName);
                  navigate("/exhibitor/vip-guest-requrest-list");
                }
              }
            })
            .catch((err) => {
              // LoginRedirection("/superadmin/login");

              if (err.response.status === 401) {
                navigate("/superadmin/login");
                localStorage.removeItem("jff_token");
                localStorage.removeItem("category");
                localStorage.removeItem("permission");
                localStorage.removeItem("user_id");
                localStorage.removeItem("type_of_user");
              }
            });
        }
        // }
      }
    }
  };
  const emptyField = () => {
    setGst_number("");
    setVisitorName("");
    setNumber("");
    setEmail("");
    setCompanyName("");
    setDesignation("");
    setYourCompany("");
    setVisiting_as("");
    setStreetAddress("");
    setstreetAddressTwo("");
    setCity("");
    setState("");
    setPostalCode("");
    // setCountrsy("");
    setHearAbout("");
    setPhoto("");
    setIDProofFront("");
    setIDProofBack("");
    setInvitedByCompany("");
    setVisitingCategory("");
    setVisitingID("");
    setInvitedCompanyName("");
    setVisitingDay("");
  };
  return (
    <>
      <>
        {/* <img
          src={MainLogo}
          style={{ maxWidth: "150px" }}
          className="m-2"
          alt=""
        /> */}
        {/* <div
          className="text-center py-2 d-flex justify-content-between"
          // style={{ backgroundColor: "rgb(61, 65, 86)" }}
        > */}
        <div className="text-center">
          <h4 className="form-heading">{formHeader}</h4>
        </div>

        <section style={{ marginBottom: '60px' }}>
          <form onSubmit={handleSubmit}>

            <div className="container">
              <div className="form-body-section">
                <div className="form-body">
                  <div className="row">
                    {formJson[formName].includes("country_array") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Country <font color="#FF0000">*</font>
                        </label>
                        <Select
                          className="my-2"
                          options={countries.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          value={
                            country
                              ? { value: country, label: country }
                              : null
                          }
                          onChange={(selectedOption) =>
                            setCountry(
                              selectedOption ? selectedOption.value : null
                            )
                          }
                          isSearchable
                          required
                          placeholder="Select designation"
                        />
                      </div>
                    )}

                    {formJson[formName].includes("mobile") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          {formName === "exhibitor_vip" ? (
                            <>
                              Buyer's Mobile Number <font color="#FF0000">*</font>
                            </>
                          ) : (
                            <>
                              Mobile Number <font color="#FF0000">*</font>
                            </>
                          )}
                        </label>
                        <input
                          className="form-control my-2"
                          placeholder="Mobile No."
                          value={number}
                          maxLength={country === "India" ? 10 : 20}
                          onChange={handleMobileNoChange}
                          required
                        />
                        {duplicateError && (
                          <p className="text-danger" style={{ fontSize: "10px" }}>
                            This buyer is already registered
                          </p>
                        )}
                      </div>
                    )}
                    {formJson[formName].includes("gst_number") && country === "India" && (
                      <div className="form-group col-lg-6 col-md-6">
                        {reloadShow && (
                          <Spinner
                            style={{
                              height: "20px",
                              width: "20px",
                              position: "absolute",
                              left: "350px",
                              top: " 278px",
                            }}
                          />
                        )}
                        <label className="fw-bold">
                          Gst Number <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          value={gst_number}
                          maxLength={15}
                          placeholder="Gst Number"
                          required
                          onChange={(e) => {
                            handleGstNumberChange(e);
                          }}
                        />
                        {showError && <p className="text-danger">{showError}</p>}
                      </div>
                    )}

                    {formJson[formName].includes("visitor_name") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Name <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          placeholder="Name"
                          value={visitorName}
                          required
                          maxLength={100}
                          onChange={(e) => {
                            handleName(e);
                          }}
                        />
                      </div>
                    )}
                    {formJson[formName].includes("email") && country !== "India" && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">Email
                          <font color="#FF0000">*</font>

                        </label>
                        <input
                          className="form-control my-2"
                          placeholder="Email"
                          required
                          onChange={(e) => {
                            handleEmailChange(e);
                          }}
                        />
                      </div>
                    )}
                    {formJson[formName].includes("company_name") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Company Name <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          placeholder="Company Name "
                          value={companyName}
                          required
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    {/* {formJson[formName].includes("designation") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label>Designation</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="form-control border my-2"
                    >
                      {designation ? designation : "Select designation"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="form-control nodesure"
                      style={{ overflowY: "scroll", maxHeight: "15vh" }}
                    >
                      {designationArray?.map((data, i) => (
                        <Dropdown.Item
                          key={i}
                          value={data}
                          onClick={() => setDesignation(data)}
                          className="d-flex justify-content-center"
                        >
                          {data}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}
                    {formJson[formName].includes("designation") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Designation <font color="#FF0000">*</font>
                        </label>
                        <Select
                          className="my-2"
                          options={designationArray.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          required
                          value={
                            designation
                              ? { value: designation, label: designation }
                              : null
                          }
                          onChange={(selectedOption) =>
                            setDesignation(
                              selectedOption ? selectedOption.value : null
                            )
                          }
                          isSearchable
                          placeholder="Select designation"
                        />
                      </div>
                    )}
                    {/* {formJson[formName].includes("visiting_as") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label>You are visiting show as</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="form-control border my-2"
                    >
                      {visiting_as ? visiting_as : "Select visiting as"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="form-control nodesure"
                      style={{ overflowY: "scroll", maxHeight: "15vh" }}
                    >
                      {visitingAsArray?.map((data, i) => (
                        <Dropdown.Item
                          key={i}
                          value={data}
                          onClick={() => setVisiting_as(data)}
                          className="d-flex justify-content-center"
                        >
                          {data}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}
                    {formJson[formName].includes("visiting_as") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          You are visiting show as
                        </label>
                        <Select
                          className="my-2"
                          required
                          options={visitingAsArray.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          value={
                            visiting_as
                              ? { value: visiting_as, label: visiting_as }
                              : null
                          }
                          onChange={(selectedOption) =>
                            setVisiting_as(
                              selectedOption ? selectedOption.value : null
                            )
                          }
                          isSearchable
                          placeholder="Select visiting as"
                        />
                      </div>
                    )}
                    {formJson[formName].includes("your_company") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label>Your Company</label>
                        <input
                          className="form-control my-2"
                          required
                          placeholder="Your Company"
                          value={yourCompany}
                          onChange={(e) => {
                            setYourCompany(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    {/* {formJson[formName].includes("invited_company") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label>Name of the company that invited you</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="form-control border my-2"
                    >
                      {invitedCompanyName
                        ? invitedCompanyName
                        : "Select visiting the show as"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="form-control nodesure"
                      style={{ overflowY: "scroll", maxHeight: "15vh" }}
                    >
                      {comapnyList?.map((data, i) => (
                        <Dropdown.Item
                          key={i}
                          value={data.company_name}
                          onClick={() => {
                            setInvitedByCompany(data._id);
                            setInvitedCompanyName(data.company_name);
                          }}
                          className="d-flex justify-content-center"
                        >
                          {data.company_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}
                    {/* {formJson[formName].includes("invited_company") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label className="fw-bold mb-2">
                    Name of the company that invited you
                  </label>
                  <Select
                    options={comapnyList.map((data) => ({
                      value: data.company_name,
                      label: data.company_name,
                    }))}
                    value={
                      invitedCompanyName
                        ? {
                            value: invitedCompanyName,
                            label: invitedCompanyName,
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      const selectedCompany = comapnyList.find(
                        (company) =>
                          company.company_name === selectedOption.value
                      );
                      setInvitedByCompany(
                        selectedCompany ? selectedCompany._id : null
                      );
                      setInvitedCompanyName(
                        selectedOption ? selectedOption.value : null
                      );
                    }}
                    isSearchable
                    placeholder="Select the company"
                  />
                </div>
              )} */}
                    {/* {formJson[formName].includes("  ") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label className="fw-bold mb-2">
                    Name of the company that invited you
                  </label>
                  <Select
                    options={comapnyList.map((data) => ({
                      value: data.company_name,
                      label: data.company_name,
                      isDisabled: data.company_name === exhibitorName, // Disable if the value matches exhibitorName
                      isSelected: data.company_name === exhibitorName, // Select if the value matches exhibitorName
                    }))}
                    value={
                      exhibitorName
                        ? {
                            value: exhibitorName,
                            label: exhibitorName,
                            isDisabled: true, // Disable the selected option
                          }
                        : null
                    }
                    onChange={(selectedOption) => {
                      const selectedCompany = comapnyList.find(
                        (company) =>
                          company.company_name === selectedOption.value
                      );
                      setInvitedByCompany(
                        selectedCompany ? selectedCompany._id : null
                      );
                      setInvitedCompanyName(
                        selectedOption ? selectedOption.value : null
                      );
                    }}
                    isSearchable
                    placeholder="Select the company"
                  />
                </div>
              )} */}
                    {formJson[formName].includes("street_add_line2") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">Address</label>
                        <input
                          className="form-control my-2"
                          placeholder="Address"
                          value={streetAddress}
                          required
                          onChange={(e) => {
                            setStreetAddress(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    {/* {formJson[formName].includes("street_add_line2") && (
                <div className="form-group col-lg-6 col-md-6 -">
                  <label className="fw-bold">Street Address Line 2</label>
                  <input
                    className="form-control my-2"
                    placeholder="Street Address Line 2"
                    onChange={(e) => {
                      setstreetAddressTwo(e.target.value);
                    }}
                  />
                </div>
              )} */}
                    {formJson[formName].includes("city") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          City <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          placeholder="City"
                          value={city}
                          required
                          onChange={(e) => {
                            handleCity(e);
                          }}
                          maxLength={25}
                        />
                      </div>
                    )}
                    {formJson[formName].includes("state") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">State</label>
                        <input
                          className="form-control my-2"
                          placeholder="State"
                          required
                          onChange={(e) => {
                            handleState(e);
                          }}
                          maxLength={25}
                        />
                      </div>
                    )}
                    {formJson[formName].includes("zipcode") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">Postal Zip Code</label>
                        <input
                          className="form-control my-2"
                          placeholder="Postal Zip Code"
                          required
                          onChange={(e) => {
                            handlePostalCode(e);
                          }}
                          maxLength={6}
                        />
                      </div>
                    )}
                    {formJson[formName].includes("country") && (
                      <div className="form-group col-lg-6 col-md-6">
                        <label className="fw-bold">
                          Country <font color="#FF0000">*</font>
                        </label>
                        <input
                          className="form-control my-2"
                          value={country}
                          required
                          placeholder="Country"
                          onChange={(e) => {
                            handleCountry(e);
                          }}
                        />
                      </div>
                    )}
                    {/* {formJson[formName].includes("hduhear") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label className="fw-bold">How do you hear about</label>
                  <input
                    className="form-control my-2"
                    placeholder="How do you hear about"
                    onChange={(e) => {
                      setHearAbout(e.target.value);
                    }}
                  />
                </div>
              )} */}
                    {/* 
              {formJson[formName].includes("visitor_category") && (
                <div className="form-group col-lg-6 col-md-6">
                  <label>Category</label>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="form-control border my-2"
                    >
                      {visitingCategory ? visitingCategory : "Select category"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="form-control nodesure"
                      style={{ overflowY: "scroll", maxHeight: "15vh" }}
                    >
                      {allCategory?.map((data, i) => (
                        <Dropdown.Item
                          key={i}
                          value={data.category_name}
                          onClick={() => {
                            setVisitingCategory(data.category_name);
                            setVisitingID(data._id);
                          }}
                          className="d-flex justify-content-center"
                        >
                          {data.category_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )} */}
                    {formJson[formName].includes("photo") && (
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label className="fw-bold">
                            Photo{" "}
                            {formName !== "exhibitor_vip" && (
                              <font color="#FF0000">*</font>
                            )}
                            {photoStatus && (
                              <span className="photo-error">
                                Only supported (JPG/JPEG/PNG)
                              </span>
                            )}
                          </label>
                          <CommonFileUploader handlePhoto={handlePhoto} fileName={photo ? photo?.name : "Select File"}
                            duplicateError={null}
                            accept=".jpg, .jpeg, .png"
                          />
                          {/* <input
                          type="file"
                          onChange={handlePhoto}
                          className="form-control"
                          accept=".jpg, .jpeg, .png"
                        /> */}
                        </div>
                      </div>
                    )}
                    {formJson[formName].includes("id_front") && (
                      <div className="col-lg-6 col-md-6 ">
                        <div className="form-group">
                          <label className="fw-bold">
                            ID Proof (Front){" "}
                            {formName !== "exhibitor_vip" && (
                              <font color="#FF0000">*</font>
                            )}
                            {idFrontStatus && (
                              <span className="photo-error">
                                Only supported (JPG/JPEG/PNG)
                              </span>
                            )}
                          </label>
                          <CommonFileUploader handlePhoto={handleIDFront} fileName={idProofFront ? idProofFront?.name : "Select File"}
                            duplicateError={null}
                            accept=".jpg, .jpeg, .png"
                          />
                          {/* <input
                          type="file"
                          onChange={handleIDFront}
                          className="form-control"
                          accept=".jpg, .jpeg, .png"
                        /> */}
                        </div>
                      </div>
                    )}
                    {formJson[formName].includes("id_back") && (
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label className="fw-bold">
                            ID Proof (Back){" "}
                            {formName !== "exhibitor_vip" && (
                              <font color="#FF0000">*</font>
                            )}
                            {idBackStatus && (
                              <span className="photo-error">
                                Only supported (JPG/JPEG/PNG)
                              </span>
                            )}
                          </label>
                          <CommonFileUploader handlePhoto={handleIDBack} fileName={idProofBack ? idProofBack?.name : "Select File"}
                            duplicateError={null}
                            accept=".jpg, .jpeg, .png"
                          />
                          {/* <input
                          type="file"
                          onChange={handleIDBack}
                          className="form-control"
                          accept=".jpg, .jpeg, .png"
                        /> */}
                        </div>
                      </div>
                    )}
                    {/* {formJson[formName].includes("visiting_day") && (
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label className="fw-bold">Visiting day</label>
                    <div className="d-flex my-2">
                      <div className="form-check me-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="day_one"
                          onClick={(e) => {
                            setVisitingDay(e.target.id);
                            console.log("assadadasdad", e.target.id);
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Day One
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="day_two"
                          onClick={(e) => {
                            setVisitingDay(e.target.id);
                            console.log("assadadasdad", e.target.id);
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          Day Two
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
                    <div className="form-submit">
                      <Button
                        type="submit"
                        // onClick={() => {
                        //   handleSubmit();
                        // }}
                        variant="btn btn-outline-dark"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </>
      <Footer />
    </>
  );
}

export default VisitorForm;

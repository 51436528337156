import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";

import DashBoard from "./DashBoard";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";
import CommonHeader from "../../../common/CommonHeader";
function ExhibitorDashboardNavbar() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [area, setArea] = useState(false);
  const [exhibitor, setExhibitor] = useState(false);
  const [vendor, setVendor] = useState(false);
  const [visitor, setVisitor] = useState(false);
  const [spotRegistration, setSpotRegistration] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [completedataByYear, setCompleteDataByYear] = useState(false);
  const [data2019, setData2019] = useState(false);
  const [agent, setAgent] = useState(false)
  const [showSetting, setshowSetting] = useState(false);
  const [hotel, setHotel] = useState(false)
  const navigate = useNavigate();

  const showSettingDropdown = (e) => {
    setshowSetting(!showSetting);
  };
  const hideSettingDropdown = (e) => {
    setshowSetting(false);
  };

  const handleLogout = (e) => {
    localStorage.removeItem("jff_token");
    localStorage.removeItem("category");
    localStorage.removeItem("permission");
    navigate("/superadmin/login");
  };

  return (
    <>
      <Navbar
        bg="dark"
        expand="lg"
        data-bs-theme="dark"
        className="bg-body-tertiary"
      >
        <CommonHeader redirection={"/superadmin/dashboard"} />

        <Container>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link onClick={() => navigate("/superadmin/dashboard")}>
                Dashboard
              </Nav.Link>
              <NavDropdown
                title="Area"
                id="collasible-nav-dropdown"
                show={area}
                onMouseEnter={() => setArea(true)}
                onMouseLeave={() => setArea(false)}
              >
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/add-area")}
                >
                  Add Area
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/area-list")}
                >
                  Area List
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Exhibitor"
                id="collasible-nav-dropdown"
                show={exhibitor}
                onMouseEnter={() => setExhibitor(true)}
                onMouseLeave={() => setExhibitor(false)}
              >
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/add-exhibitor")}
                >
                  Add Exihibitor
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/exhibitor-list")}
                >
                  Exihibitor List
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/exhibitor-report")}
                >
                  Exihibitor Report
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/vip-guest")}
                >
                  Buyers Request List
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/invited-vip-guest")}
                >
                  Invited Buyer List
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/recycled-vip")}
                >
                  Recycled Buyer list
                </NavDropdown.Item>
              </NavDropdown>
              {/* <NavDropdown
                title="Vendor"
                id="collasible-nav-dropdown"
                show={vendor}
                onMouseEnter={() => setVendor(true)}
                onMouseLeave={() => setVendor(false)}
              >
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/add-vender")}
                >
                  Add Vender
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/vender-list")}
                >
                  Vender List
                </NavDropdown.Item>
              </NavDropdown> */}
              <NavDropdown
                title="Visitor"
                id="collasible-nav-dropdown"
                show={visitor}
                onMouseEnter={() => setVisitor(true)}
                onMouseLeave={() => setVisitor(false)}
              >
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/vis-req-li")}
                >
                  Request Visitor
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/visitor-pre-approved-list")}
                >
                  Pre Approved Visitor
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/acc-vis-li")}
                >
                  Accepted Visitor
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/hol-vis-li")}
                >
                  Hold Visitor
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/rej-vis-li")}
                >
                  Rejected Visitor
                </NavDropdown.Item>
                {/* <NavDropdown.Item
                  onClick={() => navigate("/superadmin/age-li")}
                >
                  Agent List
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/app-age-li")}
                >
                  Approved Agent
                </NavDropdown.Item> */}

              </NavDropdown>
              <NavDropdown
                title="Agent"
                id="collasible-nav-dropdown"
                show={agent}
                onMouseEnter={() => setAgent(true)}
                onMouseLeave={() => setAgent(false)}
              >
                {/* <NavDropdown.Item
                  onClick={() => navigate("/superadmin/vis-req-li")}
                >
                  Request Visitor
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/visitor-pre-approved-list")}
                >
                  Pre Approved Visitor 
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/acc-vis-li")}
                >
                  Accepted Visitor
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/hol-vis-li")}
                >
                  Hold Visitor
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/rej-vis-li")}
                >
                  Rejected Visitor
                </NavDropdown.Item> */}
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/age-li")}
                >
                  Agent Request List
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/agent-pre-approved-list")}
                >
                  Pre Approved Agent
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/app-age-li")}
                >
                  Approved Agent
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/reject-agent-list")}
                >
                 Reject Agent List
                </NavDropdown.Item>

              </NavDropdown>
              <NavDropdown
                title="Hotel"
                id="collasible-nav-dropdown"
                show={hotel}
                onMouseEnter={() => setHotel(true)}
                onMouseLeave={() => setHotel(false)}
              >
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/hotel-registration")}

                >
                  Hotels
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/hotel-report")}
                >
                  Hotels Report
                </NavDropdown.Item>
              </NavDropdown>
              {/* <Nav.Link
                title="Spot-Registration"
                show={spotRegistration}
              >
                Hotels
              </Nav.Link> */}
              <Nav.Link
                title="Importers"
                show={spotRegistration}
                onClick={() => navigate("/superadmin/import-data")}
              >
                Importers
              </Nav.Link>

              <NavDropdown
                title="Setting"
                id="collasible-nav-dropdown"
                show={showSetting}
                onMouseEnter={showSettingDropdown}
                onMouseLeave={hideSettingDropdown}
              >
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/registration-setting")}
                >
                  Registration Setting
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/email-sms-setting")}
                >
                  E-Mail/SMS Setting
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/change-password")}
                >
                  Change Password
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => {
                    handleLogout("superAdmin");
                  }}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>

              {/* <NavDropdown
                title="Admin"
                id="collasible-nav-dropdown"
                show={showAdmin}
                onMouseEnter={() => setShowAdmin(true)}
                onMouseLeave={() => setShowAdmin(false)}
              >
                <NavDropdown.Item onClick={() => navigate("/superadmin/user")}>
                  User
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/superadmin/role")}>
                  Role
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => navigate("/superadmin/permission")}
                >
                  Permission
                </NavDropdown.Item>
              </NavDropdown> */}

              {/* <Nav.Link onClick={() => navigate("/superadmin/file-manager")}>
                File Manager
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
export default ExhibitorDashboardNavbar;

import React from 'react'
import Clip from '../components/HtmlMainPage/images/clip.png'

const CommonFileUploader = ({ handlePhoto, fileName, duplicateError, accept }) => {
    console.log("hello")
    return (
        <div>
            <label className="file-input-label d-flex align-items-center mt-2" style={{overflow:'hidden'}}>
                <span ><img className='clip-image' src={Clip} /></span> {/* Unicode for the clip icon */}
                <input type="file" id="addressProof" className="file-input"
                    onChange={handlePhoto}
                    disabled={duplicateError === "vip" || duplicateError === "true"}
                    style={{ background: `${(duplicateError === "vip" || duplicateError === "true") ? "#cdcdcd" : "#fff"}` }}
                    accept={accept}

                />
                <span className="placeholder-text">{fileName}</span>

            </label>
        </div>
    )
}

export default CommonFileUploader
